import React from 'react';

export function EnergyIconA() {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.15 50.12">
            <rect className="ea-cls-4" fill="#ffffff" x="69.5" y=".73" width="21.33" height="49" />
            <g id="A_CompleteCycle">
                <polygon
                    className="ea-cls-5"
                    fill="#00a651"
                    points="22.62 .77 .29 25.06 22.62 49.35 71.62 49.35 71.62 .77 22.62 .77"
                />
                <g>
                    <path
                        className="ea-cls-1"
                        stroke="#231f20"
                        strokeWidth=".82px"
                        fill="none"
                        d="M23.1,.41H90.38c.2,0,.36,.16,.36,.36V49.35c0,.2-.16,.36-.36,.36H23.1c-.1,0-.2-.04-.26-.12L.5,25.3c-.13-.14-.13-.35,0-.48L22.84,.52c.07-.07,.16-.12,.26-.12Z"
                    />
                    <path
                        className="ea-cls-6"
                        fill="#231f20"
                        d="M86.78,15.17c.08,.23,.12,.4,.14,.54,.02,.13,0,.23-.08,.3s-.19,.11-.37,.12c-.18,.02-.42,.02-.72,.02s-.57,0-.75-.01c-.18,0-.31-.03-.41-.06-.09-.03-.16-.07-.2-.12-.04-.05-.07-.12-.09-.2l-.82-2.45h-4.57l-.77,2.38c-.02,.09-.06,.16-.1,.22-.04,.06-.11,.11-.2,.14-.09,.03-.22,.06-.39,.07-.17,.01-.38,.02-.65,.02-.29,0-.51,0-.68-.03-.16-.02-.28-.06-.34-.14-.06-.07-.08-.17-.07-.31,.02-.13,.07-.31,.14-.53l3.75-10.8c.04-.11,.08-.19,.13-.26,.05-.07,.13-.12,.24-.15,.11-.03,.26-.06,.46-.07s.45-.01,.77-.01c.37,0,.66,0,.88,.01,.22,0,.39,.03,.51,.07,.12,.03,.21,.09,.26,.16s.1,.16,.14,.28l3.76,10.79Zm-5.58-8.92h0l-1.72,5.17h3.45l-1.72-5.17Z"
                    />
                </g>
                <line
                    className="ea-cls-3"
                    fill="none"
                    stroke="#231f20"
                    strokeWidth="1.07px"
                    x1="81.33"
                    y1="30.37"
                    x2="81.33"
                    y2="20.01"
                />
                <polyline
                    className="ea-cls-3"
                    fill="none"
                    stroke="#231f20"
                    strokeWidth="1.07px"
                    points="84.08 22.71 81.33 19.96 78.59 22.71"
                />
                <path
                    className="ea-cls-6"
                    fill="#231f20"
                    d="M86.07,35.95c0,.18,0,.33-.02,.46-.01,.13-.03,.23-.06,.31-.03,.08-.06,.13-.09,.16-.04,.03-.08,.05-.13,.05-.07,0-.19-.06-.36-.17-.17-.11-.39-.23-.66-.36-.27-.13-.6-.25-.98-.36s-.82-.17-1.33-.17c-.54,0-1.02,.1-1.46,.3-.44,.2-.81,.49-1.12,.85s-.55,.81-.71,1.33c-.17,.52-.25,1.09-.25,1.71,0,.69,.08,1.29,.25,1.81,.17,.52,.4,.96,.71,1.31s.67,.62,1.09,.8c.43,.18,.9,.27,1.41,.27,.25,0,.5-.03,.75-.09,.25-.06,.47-.15,.69-.28v-2.79h-2.1c-.1,0-.18-.07-.24-.21-.06-.14-.09-.38-.09-.71,0-.17,0-.32,.02-.44,.01-.12,.04-.21,.06-.28,.03-.07,.06-.12,.1-.16s.09-.05,.14-.05h3.75c.09,0,.17,.02,.24,.05,.07,.03,.13,.08,.19,.15s.1,.15,.12,.25c.03,.1,.04,.21,.04,.33v4.78c0,.19-.03,.35-.09,.49-.06,.14-.18,.25-.37,.33-.19,.08-.42,.16-.69,.25s-.56,.16-.85,.22c-.29,.06-.59,.11-.89,.14-.3,.03-.6,.05-.91,.05-.89,0-1.68-.14-2.38-.41s-1.29-.67-1.78-1.2c-.49-.52-.86-1.16-1.12-1.91-.26-.75-.39-1.6-.39-2.56s.14-1.87,.41-2.65c.27-.78,.66-1.45,1.17-2s1.11-.97,1.82-1.26c.71-.29,1.51-.44,2.38-.44,.48,0,.92,.04,1.31,.11,.4,.07,.74,.16,1.03,.26s.54,.21,.73,.33c.19,.12,.33,.22,.41,.31,.08,.09,.13,.21,.17,.37,.03,.16,.05,.39,.05,.7Z"
                />
                <path
                    className="ea-cls-2"
                    fill="#ffffff"
                    stroke="#231f20"
                    strokeWidth=".5px"
                    d="M55.63,36.15c.14,.45,.22,.8,.26,1.06,.03,.26-.01,.45-.14,.58s-.35,.21-.67,.24c-.32,.03-.76,.05-1.32,.05s-1.04,0-1.36-.03-.58-.06-.75-.11c-.17-.06-.29-.13-.36-.23-.07-.1-.13-.23-.17-.39l-1.49-4.83h-8.34l-1.41,4.7c-.05,.17-.11,.32-.18,.44-.07,.12-.19,.21-.36,.28-.17,.07-.4,.11-.7,.14-.3,.02-.7,.04-1.19,.04-.53,0-.94-.02-1.24-.06s-.5-.13-.62-.27c-.11-.14-.15-.34-.12-.6,.03-.26,.12-.61,.26-1.04l6.85-21.31c.07-.21,.15-.38,.24-.51,.09-.13,.24-.23,.44-.3,.2-.07,.48-.11,.83-.13s.82-.03,1.41-.03c.67,0,1.21,0,1.61,.03s.71,.06,.94,.13,.38,.17,.48,.31,.18,.32,.25,.56l6.86,21.3Zm-10.19-17.62h-.02l-3.14,10.21h6.3l-3.14-10.21Z"
                />
            </g>
        </svg>
    );
}
