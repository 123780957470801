import React from 'react';

import { EnergyRating } from './components';
import classes from './EnergyRatingLabel.scss';

type EnergyRatingLabelProps = {
    energyLabelImage?: string;
    inProductList?: boolean;
    letter: string;
    specificationLink?: string | null;
    variant: 'basket' | 'category-list' | 'pdp';
};

export function EnergyRatingLabel(props: EnergyRatingLabelProps) {
    const hasEnergyLabelImage = props.energyLabelImage;

    return (
        <div className={classes.energyLabel} data-variant={props.variant}>
            {hasEnergyLabelImage && (
                <a href={props.energyLabelImage} rel="noreferrer" target="_blank">
                    <EnergyRating letter={props.letter} />
                </a>
            )}

            {!hasEnergyLabelImage && (
                <div>
                    <EnergyRating letter={props.letter} />
                </div>
            )}

            {props.specificationLink && props.specificationLink.length > 0 && (
                <a
                    href={props.specificationLink}
                    rel="noreferrer"
                    target="_blank"
                    className={classes.productSpecificationSheet}
                >
                    Product Information Sheet
                </a>
            )}
        </div>
    );
}
