import React from 'react';

export function EnergyIconG() {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.15 50.12">
            <rect className="eg-cls-4" fill="#ffffff" x="69.5" y=".73" width="21.33" height="49" />
            <g id="G_CompleteCycle">
                <polygon
                    className="eg-cls-5"
                    fill="#ed1c24"
                    points="22.62 .77 .29 25.06 22.62 49.35 71.62 49.35 71.62 .77 22.62 .77"
                />
                <g>
                    <path
                        className="eg-cls-1"
                        fill="none"
                        stroke="#231f20"
                        strokeWidth=".82px"
                        d="M23.1,.41H90.38c.2,0,.36,.16,.36,.36V49.35c0,.2-.16,.36-.36,.36H23.1c-.1,0-.2-.04-.26-.12L.5,25.3c-.13-.14-.13-.35,0-.48L22.84,.52c.07-.07,.16-.12,.26-.12Z"
                    />
                    <path
                        className="eg-cls-6"
                        fill="#231f20"
                        d="M86.78,15.17c.08,.23,.12,.4,.14,.54,.02,.13,0,.23-.08,.3s-.19,.11-.37,.12c-.18,.02-.42,.02-.72,.02s-.57,0-.75-.01c-.18,0-.31-.03-.41-.06-.09-.03-.16-.07-.2-.12-.04-.05-.07-.12-.09-.2l-.82-2.45h-4.57l-.77,2.38c-.02,.09-.06,.16-.1,.22-.04,.06-.11,.11-.2,.14-.09,.03-.22,.06-.39,.07-.17,.01-.38,.02-.65,.02-.29,0-.51,0-.68-.03-.16-.02-.28-.06-.34-.14-.06-.07-.08-.17-.07-.31,.02-.13,.07-.31,.14-.53l3.75-10.8c.04-.11,.08-.19,.13-.26,.05-.07,.13-.12,.24-.15,.11-.03,.26-.06,.46-.07s.45-.01,.77-.01c.37,0,.66,0,.88,.01,.22,0,.39,.03,.51,.07,.12,.03,.21,.09,.26,.16s.1,.16,.14,.28l3.76,10.79Zm-5.58-8.92h0l-1.72,5.17h3.45l-1.72-5.17Z"
                    />
                </g>
                <line
                    className="eg-cls-3"
                    fill="none"
                    stroke="#231f20"
                    strokeWidth="1.07px"
                    x1="81.33"
                    y1="30.37"
                    x2="81.33"
                    y2="20.01"
                />
                <polyline
                    className="eg-cls-3"
                    fill="none"
                    stroke="#231f20"
                    strokeWidth="1.07px"
                    points="84.08 22.71 81.33 19.96 78.59 22.71"
                />
                <path
                    className="eg-cls-6"
                    fill="#231f20"
                    d="M86.07,35.95c0,.18,0,.33-.02,.46-.01,.13-.03,.23-.06,.31-.03,.08-.06,.13-.09,.16-.04,.03-.08,.05-.13,.05-.07,0-.19-.06-.36-.17-.17-.11-.39-.23-.66-.36-.27-.13-.6-.25-.98-.36s-.82-.17-1.33-.17c-.54,0-1.02,.1-1.46,.3-.44,.2-.81,.49-1.12,.85s-.55,.81-.71,1.33c-.17,.52-.25,1.09-.25,1.71,0,.69,.08,1.29,.25,1.81,.17,.52,.4,.96,.71,1.31s.67,.62,1.09,.8c.43,.18,.9,.27,1.41,.27,.25,0,.5-.03,.75-.09,.25-.06,.47-.15,.69-.28v-2.79h-2.1c-.1,0-.18-.07-.24-.21-.06-.14-.09-.38-.09-.71,0-.17,0-.32,.02-.44,.01-.12,.04-.21,.06-.28,.03-.07,.06-.12,.1-.16s.09-.05,.14-.05h3.75c.09,0,.17,.02,.24,.05,.07,.03,.13,.08,.19,.15s.1,.15,.12,.25c.03,.1,.04,.21,.04,.33v4.78c0,.19-.03,.35-.09,.49-.06,.14-.18,.25-.37,.33-.19,.08-.42,.16-.69,.25s-.56,.16-.85,.22c-.29,.06-.59,.11-.89,.14-.3,.03-.6,.05-.91,.05-.89,0-1.68-.14-2.38-.41s-1.29-.67-1.78-1.2c-.49-.52-.86-1.16-1.12-1.91-.26-.75-.39-1.6-.39-2.56s.14-1.87,.41-2.65c.27-.78,.66-1.45,1.17-2s1.11-.97,1.82-1.26c.71-.29,1.51-.44,2.38-.44,.48,0,.92,.04,1.31,.11,.4,.07,.74,.16,1.03,.26s.54,.21,.73,.33c.19,.12,.33,.22,.41,.31,.08,.09,.13,.21,.17,.37,.03,.16,.05,.39,.05,.7Z"
                />
                <path
                    className="eg-cls-2"
                    fill="#ffffff"
                    stroke="#231f20"
                    strokeWidth=".5px"
                    d="M55.25,17.68c0,.36-.01,.67-.04,.93-.03,.26-.07,.47-.12,.62-.05,.16-.11,.26-.19,.33-.07,.06-.16,.09-.27,.09-.15,0-.39-.11-.72-.33-.33-.22-.77-.46-1.32-.72-.55-.26-1.2-.5-1.97-.72-.76-.22-1.64-.33-2.65-.33-1.08,0-2.05,.2-2.92,.6-.87,.4-1.61,.97-2.23,1.71-.62,.74-1.09,1.62-1.42,2.65-.33,1.03-.5,2.18-.5,3.43,0,1.37,.17,2.58,.51,3.63,.34,1.05,.81,1.92,1.42,2.63,.61,.71,1.34,1.24,2.19,1.6,.85,.36,1.79,.54,2.82,.54,.5,0,1-.06,1.49-.19,.49-.12,.95-.31,1.37-.56v-5.57h-4.21c-.21,0-.37-.14-.48-.42-.11-.28-.17-.75-.17-1.42,0-.35,.01-.64,.04-.87,.03-.23,.07-.42,.13-.57,.06-.14,.13-.25,.21-.32s.17-.1,.27-.1h7.5c.18,0,.35,.03,.49,.1s.27,.17,.38,.31c.11,.14,.19,.3,.25,.49,.06,.19,.09,.41,.09,.66v9.56c0,.37-.06,.7-.18,.98-.12,.28-.37,.5-.74,.66-.37,.16-.83,.33-1.38,.5-.55,.17-1.12,.32-1.71,.45s-1.18,.22-1.78,.28-1.2,.09-1.81,.09c-1.77,0-3.36-.28-4.76-.83-1.4-.55-2.59-1.35-3.56-2.39-.97-1.05-1.72-2.32-2.23-3.83-.52-1.5-.77-3.21-.77-5.11s.27-3.73,.82-5.3c.55-1.57,1.33-2.9,2.33-3.99,1.01-1.1,2.22-1.93,3.65-2.52,1.42-.58,3.01-.87,4.76-.87,.96,0,1.84,.07,2.63,.21,.79,.14,1.48,.32,2.07,.52s1.08,.42,1.47,.66c.39,.24,.66,.44,.82,.61,.15,.17,.27,.42,.33,.73,.07,.32,.1,.78,.1,1.4Z"
                />
            </g>
        </svg>
    );
}
