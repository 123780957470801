import React from 'react';

import { EnergyIconA } from './EnergyIconA';
import { EnergyIconB } from './EnergyIconB';
import { EnergyIconC } from './EnergyIconC';
import { EnergyIconD } from './EnergyIconD';
import { EnergyIconE } from './EnergyIconE';
import { EnergyIconF } from './EnergyIconF';
import { EnergyIconG } from './EnergyIconG';

export function EnergyRating(props: { letter?: string }) {
    switch (props.letter) {
        case 'A':
            return <EnergyIconA />;
        case 'B':
            return <EnergyIconB />;
        case 'C':
            return <EnergyIconC />;
        case 'D':
            return <EnergyIconD />;
        case 'E':
            return <EnergyIconE />;
        case 'F':
            return <EnergyIconF />;
        case 'G':
            return <EnergyIconG />;
        default:
            return <EnergyIconA />;
    }
}
